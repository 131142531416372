<template>
  <div ref="fade" class="fadein">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SlideInComponent',
  data () {
    return {
    }
  },
}
</script>

<style scoped>
.fadein {
  animation: fadeIn 2s ease-out .5s 1 normal backwards;
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-8rem);
  }
  100% {
    opacity: 1;
  }
}
</style>
<template>
  <div class="header">
    <v-app-bar app color="#212121" dark hide-on-scroll max-height="80">
      <div class="d-flex align-center logobox">
        <a href="https://bettercoffee.link">
        <span class="logo">Better Coffee</span>
        </a>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "HeaderComponent",
  data() {
    return {
      drawer: false,
      icons: [
        'mdi-twitter',
        'mdi-instagram',
      ],
      baseURL: axios.defaults.baseURL
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@500&display=swap');

.logo {
  font-family: 'Shippori Mincho', serif;
  font-size: 3rem;
  padding: 0rem 2rem;
  color: white;
  max-height: 100%;
}

.logobox {

  max-height: 100%;
}
.logobox a {
  text-decoration:none;

}

header nav {
  height: 100%;
}
header nav .btn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 20px;
}
header nav .btn span {
  position: absolute;
  left: 10px;
  width: 20px;
  height: 2px;
  transition: 0.5s ease;
}
header nav .btn span:nth-child(1) {
  top: 12px;
}
header nav .btn span:nth-child(2) {
  top: 19px;
}
header nav .btn span:nth-child(3) {
  top: 26px;
}
header nav ul {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
header nav ul li {
  margin: 15px 0;
}
header nav ul li a {
  display: block;
  padding: 5px;
}
.dot {
  text-align: center;
  color: white;
  font-size: 2rem;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 650px) {
  .logo {
  font-size: 2.3rem;
  }
  .header {
    height: 10px;
  }
}
@media screen and (max-width: 460px) {
  .logobox {
    width: 100%;
    justify-content: center;
  }
}
</style>
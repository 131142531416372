<template>
  <v-app>
    <HeaderComponent />
    <TopComponent />
    <v-main>
      <BodyComponent />
    </v-main>
    <FooterComponent />
  </v-app>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import TopComponent from './components/TopComponent.vue';
import BodyComponent from './components/BodyComponent.vue';

export default {
  name: 'App',

  components: {
    HeaderComponent,
    FooterComponent,
    TopComponent,
    BodyComponent
},

  data: () => ({
  }),
};
</script>

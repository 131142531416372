<template>
<div>
    <div class="top">
      <v-img
    src="../assets/Cappuccino.jpg"
    gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
    :height="height"
    ></v-img>
      <div class="slide font-weight-thin">
      <SlideIn>
      <p>Better Coffee, Better World.</p>
      </SlideIn></div>
    </div>

</div>
</template>

<script>
import SlideIn from './SlideIn.vue'

export default {
  name: 'TopComponent',
  components: { SlideIn },
  data() {
    return {
      height: 400
    }
  },
  created() {
    this.height=window.innerHeight + 1;
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@500&display=swap');
.top {
  position: relative;
  }

.top .slide {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin:0;
  padding:0;
  color: white;
  font-size: 6rem;
  width: 90%;
  margin: 0rem auto;
  text-align: center;
  }

.top img {
  width: 100%;
  }
@media screen and (max-width: 1245px) {
  .top .slide {
  font-size: 5rem;
  }
}
@media screen and (max-width: 1040px) {
  .top .slide {
  font-size: 4rem;
  }
}
@media screen and (max-width: 920px) {
  .top .slide {
  font-size: 6rem;
  }
}
@media screen and (max-width: 650px) {
  .top .slide {
  font-size: 4.5rem;
  }
}
@media screen and (max-width: 465px) {
  .top .slide {
  font-size: 3.5rem;
  }
}
@media screen and (max-width: 400px) {
  .top .slide {
  font-size: 3rem;
  }
}
</style>
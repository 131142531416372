<template>
  <div class="home">
    <section id="concept" class="concept" name="concept">
      <div class="content">
        <FadeInComponent>
          <h2>CONCEPT</h2>
        </FadeInComponent>
        <div class="inner">
          <FadeInComponent>
            <img src="../assets/Concept.jpg" alt="">
          </FadeInComponent>
          <FadeInComponent>
            <h3>”Better Coffee, Better World.”</h3>
            <p>良い発想は良い休憩から。<br class="pc">より良いコーヒー体験で、より良い世界を目指します。</p>
            <a href="https://cafespace.net/2022/08/06/better-coffee%e3%81%ae%e7%9b%ae%e6%8c%87%e3%81%99%e4%b8%96%e7%95%8c/" target="_blank" rel="noopener" class="link">詳細に記載したブログはこちら</a>
          </FadeInComponent>
        </div>
      </div>
    </section>
    <section id="our-coffee" class="our-coffee" name="our-coffee">
      <div class="content">
        <FadeInComponent>
          <h2>OUR COFFEE</h2>
        </FadeInComponent>
        <div class="inner">
          <FadeInComponent>
            <img src="../assets/OurCoffee.jpg" alt="">
          </FadeInComponent>
          <FadeInComponent>
            <h3>”High Quality and Attractive”</h3>
            <p>高品質で、魅力的な酸味と甘みのある美味しいコーヒー。<br class="pc">コーヒーの魅力で気分を切り替えます。</p>
          </FadeInComponent>
        </div>
      </div>
    </section>
    <section id="beans" class="beans" name="online-store">
      <div class="content">
        <FadeInComponent>
          <h2>ONLINE STORE</h2>
        </FadeInComponent>
        <div class="inner">
          <FadeInComponent>
            <a href="https://shop.bettercoffee.link/" target="_blank" rel="noopener" class="link">ONLINE STORE</a>
          </FadeInComponent>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FadeInComponent from './FadeIn.vue'

export default {
  name: 'BetterCoffee',
  components: { FadeInComponent },
  data() {
    return {
      height: 0
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  width: 70%;
  /* max-width: 1080px; */
  margin-left: auto;
  margin-right: auto;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.inner img {
  width: 100%;
  /* max-width: 1080px; */
}

.concept h2,
.our-coffee h2,
.beans h2 {
  font-size: 3rem;
  font-weight: 700;
  padding: 0rem 0 1rem;
}

.concept h3,
.our-coffee h3,
.beans h3 {
  font-size: 4rem;
  font-weight: 150;
  line-height: 1.5;
  padding: 3rem 0 2rem;
}

@media screen and (max-width: 750px) {

  .concept h3,
  .our-coffee h3,
  .beans h3 {
    padding: 30px 20px 20px;
  }
}

p, a {
  font-size: 2rem;
}

.home {
  margin-left: auto;
  margin-right: auto;

}

.our-coffee {
  /* background-color: #FFEFDD; */
  background-color: #F5F5F5;
}
@media screen and (max-width: 650px) {
  .concept h2,
  .our-coffee h2,
  .beans h2 {
    font-size: 2rem;
    font-weight: 700;
    padding: 0rem 0 1rem;
  }

  .concept h3,
  .our-coffee h3,
  .beans h3 {
    font-size: 2rem;
    font-weight: 150;
    line-height: 1.5;
    padding: 3rem 0 2rem;
  }

  p, a {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1000px) {
  .content {
    width: 90%;
  }

  .concept h3,
  .our-coffee h3,
  .beans h3 {
    font-size: 2.5rem;
    font-weight: 150;
    line-height: 1.5;
    padding: 3rem 0 2rem;
  }
}
@media screen and (max-width: 1500px) {
  .content {
    width: 80%;
  }
}
@media screen and (max-width: 480px) {
  .concept h3,
  .our-coffee h3,
  .beans h3 {
    font-size: 1.4rem;
    font-weight: 150;
    line-height: 1.5;
    padding: 3rem 0 2rem;
  }
  p, a {
    font-size: 1.2rem;
  }
}
.link {
  color: #424242;
}
</style>
